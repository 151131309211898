import territort from "@/assets/json/territory.json";
import addKeywordPage from '../../addKeywordPage'
import _ from "underscore";
import store from "element-ui/packages/cascader-panel/src/store";
import da from "element-ui/src/locale/lang/da";

export default {
    name: "CreatePlanStepThree",
    data() {
        return {
            props: { multiple: true, value: 'id', label: 'name', },
            form: {
                name: '',
                price: ''
            },
            tableData: [],
            goodsListed: [], //选中的商品
            //是否显示选择商品
            selectGoods: true,
            //是否显示定向设置
            targetingSettings: false,
            areaDrawer: false,
            //地区
            territortList: [],
            radio2: 1,
            keywordDrawer: false,
            //定向推荐人群表格数据
            tableData3: [{
                    premiumCrowd: '核心人群',
                    premiumFactor: 100,
                    clickRate: 0,
                    tranRate: 0,
                    exposureCrowd: 0,
                    exposureQuantity: 0,
                    switchValue: false,
                    show_rank: 0
                },
                {
                    premiumCrowd: '认知人群',
                    premiumFactor: 100,
                    clickRate: 0,
                    tranRate: 0,
                    exposureCrowd: 0,
                    exposureQuantity: 0,
                    switchValue: false,
                    show_rank: 0
                },
                {
                    premiumCrowd: '意向人群',
                    premiumFactor: 100,
                    clickRate: 0,
                    tranRate: 0,
                    exposureCrowd: 0,
                    exposureQuantity: 0,
                    switchValue: false,
                    show_rank: 0
                },
                {
                    premiumCrowd: '竞品人群',
                    premiumFactor: 100,
                    clickRate: 0,
                    tranRate: 0,
                    exposureCrowd: 0,
                    exposureQuantity: 0,
                    switchValue: false,
                    show_rank: 0
                },
                {
                    premiumCrowd: '店铺人群',
                    premiumFactor: 100,
                    clickRate: 0,
                    tranRate: 0,
                    exposureCrowd: 0,
                    exposureQuantity: 0,
                    switchValue: false,
                    show_rank: 0
                },
                {
                    premiumCrowd: '类目相关人群',
                    premiumFactor: 100,
                    clickRate: 0,
                    tranRate: 0,
                    exposureCrowd: 0,
                    exposureQuantity: 0,
                    switchValue: false,
                    show_rank: 0
                },
            ],
            //s关键词页面
            //商品推词表格数据
            goodsKeyWordList: [],
            goodsKeyWordTmp: [],
            //已选关键词表格数据
            selectedKeywordList: [],
            activeTab: 'first',
            //手动输入关键词
            manualKeywordInput: '',
            //e关键词页面
            page: 1,
            limit: 15,
            areaSet: [],
            goodsIdRadio: 0,
            goodsId: null,
            keywordObj: {
                page: 1,
                limit: 10,
                total: 0
            },
            isPcErr: false,
            peopleCategory: [
                { key: '核心人群', value: 'kernel' },
                { key: '认知人群', value: 'perceive' },
                { key: '意向人群', value: 'intention' },
                { key: '竞品人群', value: 'products' },
                { key: '店铺人群', value: 'store' },
                { key: '类目相关人群', value: 'category' },
            ],
            dataTemplateInfo: {},
            unitNameStatus: false,
            isAllArea: false,
            isIndeterminate: false,
            form_data_edit: null,
            delKeyQueue: [],
            tmpUnitName: null,
            searchWord: '',
            storeBugget: [],
            exposureIndex: 1,
            stu_offer: 0,
            stuEditIndexId: null,
        }
    },
    components: {
        addKeywordPage
    },
    computed: {
        exposurePeopleNum() {
            let epPNum = 0;
            _.each(this.tableData3, (item) => {
                epPNum += parseFloat(item.exposureCrowd);
            });
            return epPNum;
        },
        exposureNumber() {
            let epNum = 0;
            _.each(this.tableData3, (item) => {
                epNum += parseFloat(item.exposureQuantity)
            });
            return epNum;
        },
        peopleOriented() {
            let num = 0;
            _.each(this.tableData3, (item) => {
                if (item.switchValue) {
                    num++;
                }
            });
            return num;
        }
    },
    watch: {
        $route(to, from) {
            /*监听路由变化，重置创建单元步骤*/
            this.selectGoods = true;
            this.targetingSettings = false;
        }
    },
    mounted() {
        this.territortList = territort;
        this.goodsList();
        this.moduleTemplateInfo();
        this.peopleTargeting();
        this.mainInfo();
        if (this.$route.query.id) {
            this.getPlanInfo(this.$route.query.id);
        }
        //对商品列表为出现商品做二次判断
        setTimeout(() => {
            if (this.tableData.length === 0) {
                this.goodsList();
            }
        }, 1000);
    },
    methods: {
        focusEditOffer(row) {
            this.stuEditIndexId = row.keyword_id;
            this.stu_offer = Number(row.pc_price);
        },
        mainInfo() {
            this.$http.axiosGet(this.$api.ep_kwsmain, (res) => {
                if (res.code === 200) {
                    this.storeBugget = res.data.store_data.split('#');
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        searchKW() {
            this.goodsKywsed();
        },
        initGoodsKW() {
              this.changeKeywdsList(1);
        },
        getPlanInfo(plan_id) {
            new Promise((resolve, reject) => {
                this.$http.axiosGetBy(this.$api.ep_getepd, { id: plan_id }, (res) => {
                    if (res.code === 200) {
                        let form_data = res.data;
                        this.form_data_edit = res.data;
                        this.form.name = form_data.unit_name;
                        this.tmpUnitName = form_data.unit_name;
                        if (form_data.gi_id) {
                            this.goodsId = { id: form_data.gi_id };
                            this.goodsIdRadio = this.goodsId.id;
                        }
                        this.form.price = Number(form_data.pc_price);
                        resolve(form_data.keyword_data);
                        //人群定向
                        if (form_data.people_data.length > 0) {
                            this.tableData3 = _.map(this.tableData3, (item, index) => {
                                let tmp = _.find(form_data.people_data, { type: item.premiumCrowd });
                                let people_type = _.find(this.peopleCategory, { key: item.premiumCrowd });
                                if (tmp) {
                                    item.premiumFactor = tmp.premium;
                                    item.switchValue = true;
                                    item.exposureCrowd = tmp.click_num;
                                    item.exposureQuantity = tmp.show_num;
                                }
                                return item;
                            });
                        }
                        //特定地区
                        this.areaSet = form_data.area_set;
                        if (this.areaSet.length === 0) {
                            this.radio2 = 1;
                        } else {
                            this.radio2 = 2;
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    reject(err);
                })

            }).then(res => {
                let ids = [];
                _.each(res, (item) => {
                    ids.push(item.keyword_id);
                });
                let param = {
                    nopage: 1,
                    id: ids.join(',')
                }
                this.$http.axiosGetBy(this.$api.ep_kwsdata, param, (response) => {
                    if (response.code === 200) {
                        this.selectedKeywordList = _.map(res, (item) => {
                            let val = _.find(response.data.list, { keyword_id: item.keyword_id});
                            let oneForm = this.$lockr.get('form');
                            let offer_array = val.offer_rank;
                            let time_set = oneForm.time_set;
                            let rank_array = [];
                            _.each(time_set, (time_item) => {
                                if (time_item) {
                                    let price_rank = offer_array[time_item.index];
                                    price_rank = price_rank.reverse();
                                    let offer = this.$common.toFormat2dot(Number(time_item.discount) / 100 * Number(item.offer));
                                    let position = this.$common.sortByIndex(price_rank, offer);
                                    rank_array.push(position);
                                }
                            });
                            let total = 0;
                            _.each(rank_array, (it) => {
                                total += Number(it);
                            });
                            item.bid_position = Math.floor(total / rank_array.length);
                            item.pc_price = Number(item.offer);
                            //关键词竞争店铺一天的平均的出价,人群定向排名需要
                            let store_offer = _.map(val.store_rank, (_val) => {
                                return Number(_val);
                            })
                            store_offer = _.sortBy(store_offer, (tmp_val) => {
                                return -tmp_val;
                            });
                            item.price_rank = store_offer;
                            return item;
                        });
                    } else {
                        this.$message({
                            type: 'success',
                            message: response.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })

            }).catch(err => {
                this.$common.axiosErrorMsg(err);
            })

        },
        areaChange() {
            let area_list = [];
            _.each(territort, (item) => {
                _.each(item.children, (child) => {
                    if (!child.disabled) {
                        let tmp = [
                            item.id, child.id
                        ]
                        area_list.push(tmp);
                    }
                });
            });
            if (this.isAllArea) {
                this.areaSet = area_list;
            } else {
                this.areaSet = [];
            }
        },
        cascaderPanelChange(val) {
            if (this.areaSet.length === 30) {
                this.isAllArea = true;
                this.isIndeterminate = false;
            } else if (this.areaSet.length > 0 && this.areaSet.length < 30) {
                this.isIndeterminate = true;
            } else {
                this.isIndeterminate = false;
                this.isAllArea = false;
            }
        },
        peopleTargeting() {
            this.$http.axiosGet(this.$api.ep_people, (res) => {
                if (res.code === 200) {
                    _.each(res.data, (item, index) => {
                        this.tableData3[index].clickRate = item.click_rate;
                        this.tableData3[index].tranRate = item.tran_rate;
                    });
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        peopleChanged(row) {
            if (this.selectedKeywordList.length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请先添加关键词',
                    duration: 1000
                });
                return;
            }
            if (!row.switchValue) {
                row.exposureCrowd = 0;
                row.exposureQuantity = 0;
                return;
            }
            let arr = [];
            if (this.selectedKeywordList[0]) {
                let tmp_price_rank = [].concat(this.selectedKeywordList);
                // console.log('tpr', tmp_price_rank);
                for (let i = 0; i < this.selectedKeywordList.length; i++) {
                    let keywordItem = this.selectedKeywordList[i];
                    for (let j = 0; j < keywordItem.price_rank.length; j++) {
                        let offer = Number(keywordItem.price_rank[j]);
                        if (i === 0) {
                            arr.push(offer);
                        } else {
                            arr[j] = this.$common.toFormat2dot(Number(arr[j]) + offer);
                        }
                    }
                }

                let nums = this.selectedKeywordList.length;
                arr = _.map(arr, (item) => {
                    return Number(this.$common.toFormat2dot(item / nums));
                });
            }
            // console.log('arr', arr);
            let store_bugget = this.storeBugget.reverse();

            store_bugget = _.map(store_bugget, (item) => {
                return Number(item);
            });
            store_bugget = _.sortBy(store_bugget, (item) => {
                return -item;
            });
            let offer_price = this.$common.toFormat2dot(this.form.price * row.premiumFactor / 100)
            let tmp_position = this.$common.sortByIndex(arr, offer_price);
            row.show_rank = tmp_position;
            let people_data = this.dataTemplateInfo.data_search_adv.people_data;
            let obj = _.find(this.peopleCategory, { key: row.premiumCrowd});
            let base_num = people_data[`${obj.value}_potential_client`];
            let tran_rate = people_data[`${obj.value}_tran_rate`];
            /*
            * 首先算出竞争店铺加学生的平均出价排名， 第一名=这个人群的潜在客户数， 第二名=第一名的曝光量预估-（（第二名日限额/出价）*这个人群的点击率）
              曝光人数预估=曝光量预估*这个人群的转化率
            * */
            let exposureNum = this.exposureTotal(base_num, tran_rate, tmp_position, store_bugget, arr, offer_price);
            row.exposureQuantity = exposureNum;
            row.exposureCrowd = Math.floor(exposureNum * tran_rate / 100);
        },
        exposureTotal(exposure_num, tran_rate, position, store_bugget, arr, offer) {
            position = Number(position);
            if (position === this.exposureIndex) {
                this.exposureIndex = 1;
                return exposure_num;
            } else {
                this.exposureIndex++;
                let dailybugget = 0;
                let offer_price = (this.exposureIndex >= arr.length) ? offer : arr[this.exposureIndex];
                if (this.exposureIndex > arr.length) {
                    let form = this.$lockr.get('form');
                    if (form.daily_budget) {
                        dailybugget = Number(form.daily_budget);
                    } else {
                        dailybugget = store_bugget[store_bugget.length - 1];
                    }

                } else {
                    dailybugget = store_bugget[this.exposureIndex - 1];
                }
                exposure_num = exposure_num - Math.floor((dailybugget / offer_price) * tran_rate / 100);
                return this.exposureTotal(exposure_num, tran_rate, position, store_bugget, arr, offer);
            }
        },
        changeRankingPrice(row, index) {
            if (isNaN(Number(this.stu_offer)) || Number(this.stu_offer) === 0) {
                this.$message({
                    type: 'warning',
                    message: '请设置正确的PC出价，且出价不能为0',
                    duration: 1000,
                });
                return;
            }
            row.pc_price = Number(this.stu_offer);
            row.offer = row.pc_price;
            // row.bid_position = this.$common.sortByIndex(row.price_rank, parseFloat(row.pc_price));
            this.stuEditIndexId = null;
            let param = {
                nopage: 1,
                id: row.keyword_id
            }
            this.$http.axiosGetBy(this.$api.ep_kwsdata, param, (res) => {
                if (res.code === 200) {
                    let keywordInfo = res.data.list[0];
                    //该关键的每个时间的出价
                    let offer_array = keywordInfo.offer_rank;
                    //获取学生设置了的时间段
                    let oneForm = this.$lockr.get('form');
                    let time_set = oneForm.time_set;
                    let rank_array = [];
                    _.each(time_set, (item) => {
                        if (item) {
                            let price_rank = offer_array[item.index];
                            price_rank = price_rank.reverse();
                            let offer = this.$common.toFormat2dot(Number(item.discount)/100 * row.pc_price);
                            let position = this.$common.sortByIndex(price_rank, offer);
                            rank_array.push(position);
                        }
                    });
                    let total = 0;
                    _.each(rank_array, (item) => {
                        total += Number(item);
                    });
                    row.bid_position = Math.floor(total / rank_array.length);
                    this.$set(this.selectedKeywordList, index, row);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        checkedPcPrice() {
            if (Number(this.form.price) === 0) {
                this.$notify({
                    title: '警告',
                    message: '请先设置PC出价系数',
                    type: 'warning'
                });
                this.isPcErr = true;
                return false;
            } else {
                this.isPcErr = false;
                _.each(this.tableData3, (item, index) => {
                    if (item.switchValue) {
                        this.peopleChanged(this.tableData3[index]);
                    }
                });
            }
            let param = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
            if (param.test(this.form.price)) {
                this.form.price = Number(this.form.price);
            } else {
                this.$notify({
                    title: '警告',
                    message: '请输入正整数，小数最多保留两位',
                    type: 'warning'
                });
                this.form.price = 0;
            }
        },
        changeKeywdsList(val) {
            this.keywordObj.page = val;
            this.goodsKywsed();
        },
        handleSelectKeywords(keywords) {
            _.each(keywords, (item) => {
                let isTmp = _.find(this.selectedKeywordList, {keyword_id: item.keyword_id});
                if (!isTmp) {
                    let tmp = {
                        id: item.id,
                        keyword_id: item.keyword_id,
                        keyword: item.keyword,
                        pc_price: this.form.price,
                        offer: Number(this.form.price),
                        com_index: item.quality_score,
                        bid_position: 0,
                    }
                    let oneForm = this.$lockr.get('form');
                    tmp.price_rank = _.map(item.store_rank, (value, index) => {
                        return parseFloat(value);
                    });
                    /*price_rank 在人群定向排名要涉及到*/
                    tmp.price_rank = _.sortBy(tmp.price_rank, (_item) => {
                        return -_item;
                    });
                    let offer_array = item.offer_rank;
                    let time_set = oneForm.time_set;
                    let rank_array = [];
                    _.each(time_set, (item) => {
                        if (item) {
                            let offer_rank = offer_array[item.index];
                            offer_rank = offer_rank.reverse();
                            let offer = this.$common.toFormat2dot(this.form.price * Number(item.discount) / 100);
                            let position = this.$common.sortByIndex(offer_rank, offer);
                            rank_array.push(position);
                        }
                    });
                    let total = 0;
                    _.each(rank_array, (item) => {
                        total += Number(item);
                    });
                    tmp.bid_position = Math.floor(total / rank_array.length);
                    this.selectedKeywordList.push(tmp);
                    // console.log('排名参考', tmp);
                }
            });
        },
        goodsIdChecked(row) {
            this.goodsId = row;
            // this.keywords(row.id);
        },
        keywords(goodsId) {
            let param = {
                gi_id: goodsId
            }
            this.$http.axiosGetBy(this.$api.SDkeywords, param, (res) => {
                if (res.code === 200) {
                    this.goodsKeyWordTmp = res.data;
                    this.keywordObj.total = res.data.length;
                    this.changeKeywdsList(1);
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        completeAreaSet() {
            if (this.areaSet.length === 0) {
                this.radio2 = 1;
            }
            this.areaDrawer = false;
        },
        moduleTemplateInfo() {
            this.$http.axiosPost(this.$api.ep_getsd, {}, (res) => {
                if (res.code === 200) {
                    this.dataTemplateInfo = res.data;
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        goodsList() {
            let param = {
                type: 'page',
                page: this.page,
                limit: this.limit
            }
            this.$http.axiosGetBy(this.$api.ep_goods, param, (res) => {
                if (res.code === 200) {
                    this.tableData = res.data.data;
                } else {
                    this.$message.warning(res.msg);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            });
        },
        handleSelectionChange(val) {
            this.goodsListed = val;
        },
        selectTerritory(val) {
            if (val === 2) {
                this.areaDrawer = true;
            } else {
                this.areaSet = [];
                this.isIndeterminate = false;
                this.isAllArea = false;
            }
        },
        showTerritory() {
            this.areaDrawer = true;
        },
        //添加关键词
        addKeyword() {
            if (!this.form.price) {
                this.isPcErr = true;
                this.$message({
                    type: 'warning',
                    message: '请先设置PC出价系数',
                });
                return;
            }
            this.keywordDrawer = true;
        },
        toBack() {
            this.$router.push({
                path: '/student/operationpromotion/searchads'
            })
        },
        toNext() {
            if (!this.form.name) {
                this.$message.warning('请输入单元名称');
                return false;
            } else if (this.tmpUnitName !== this.form.name) {
                new Promise((resolve, reject) => {
                    let form = this.$lockr.get('form');
                    let param = {
                        project_name: form.project_name,
                        unit_name: this.form.name
                    }
                    if (this.$route.query.id) {
                        param.id = this.$route.query.id
                    }
                    this.$http.axiosGetBy(this.$api.SDcheckPN, param, (res) => {
                        resolve(res);
                    }, (err) => {
                        reject(err);
                    })
                }).then(res => {
                    if (res.code === 200) {
                        if (!res.data.is_repeat) {
                            this.unitNameStatus = true;
                            this.toNextCommon();
                        } else {
                            this.$message.warning("单元名称已存在");
                            return;
                        }
                    } else {
                        this.$message.warning(res.msg);
                        return;
                    }
                }).catch(err => {
                    this.$common.axiosErrorMsg(err);
                })
            } else {
                this.toNextCommon();
            }

        },
        toNextCommon () {
            if (this.goodsId === null) {
                this.$message.warning('请选择商品');
                return false;
            }
            this.selectGoods = false;
            this.targetingSettings = true;
            if (this.$route.query.id) {
                _.each(this.form_data_edit.keyword_data, (item) => {
                    let keyword = _.find(this.goodsKeyWordTmp, { id: item.keyword_id });
                    if (keyword) {
                        let tmp = {
                            id: keyword.id,
                            keyword: keyword.keyword,
                            pc_price: Number(this.form_data_edit.pc_price),
                            com_index: keyword.com_index,
                            bid_position: 0
                        }
                        tmp.price_rank = _.map(keyword.price_rank, (value, index) => {
                            return parseFloat(value);
                        });
                        tmp.bid_position = this.$common.sortByIndex(tmp.price_rank, tmp.pc_price);
                        this.selectedKeywordList.push(tmp);
                    }
                });
            }
        },
        goodsKywsed() {
            //左侧表格选中状态
            // console.log('&&&', this.selectedKeywordList);return;
            let param = {
                page: this.keywordObj.page,
                limit: this.keywordObj.limit
            };
            if (this.searchWord.trim().length > 0) {
                param.keyword = this.searchWord;
            }
            this.$http.axiosGetBy(this.$api.ep_kwsdata, param, (res) => {
                if (res.code === 200) {
                    this.goodsKeyWordList = _.map(res.data.list, (item) => {
                        if (this.keywordObj.page === 1) {
                            item.buy_word_heat = 5;
                        } else if (this.keywordObj.page > 1 && this.keywordObj.page <= 3) {
                            item.buy_word_heat = 4;
                        } else if (this.keywordObj.page > 3 && this.keywordObj.page <= 6) {
                            item.buy_word_heat = 3;
                        } else if (this.keywordObj.page > 6 && this.keywordObj.page <= 10) {
                            item.buy_word_heat = 2;
                        } else if (this.keywordObj > 10) {
                            item.buy_word_heat = 1;
                        }
                        return item;
                    });
                    this.keywordObj.total = res.data.total;
                    //左侧表格选中的状态
                    if (this.selectedKeywordList.length === 0) {
                        this.$refs.goodsKeywordsTable.clearSelection();
                        return;
                    }
                    _.each(this.goodsKeyWordList, (item, index) => {
                        let tmp = _.find(this.selectedKeywordList, { keyword_id: item.keyword_id });
                        if (tmp) {
                            this.$nextTick(() => {
                                this.$refs.goodsKeywordsTable.toggleRowSelection(this.goodsKeyWordList[index], true);
                            });
                        } else {
                            this.$nextTick(() => {
                                this.$refs.goodsKeywordsTable.toggleRowSelection(this.goodsKeyWordList[index], false);
                            });
                        }
                    });
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            });
            //针对于时间的修改,重新获取一下已选关键词的排名
            let oneForm = this.$lockr.get('form');
            let time_set = oneForm.time_set;
            let ids = [];
            if (this.selectedKeywordList.length === 0) {
                return;
            }
            if (this.selectedKeywordList.length) {
                _.each(this.selectedKeywordList, (_item) => {
                    ids.push(_item.keyword_id);
                });
            }
            if (!ids.length) {
                return
            }
            this.$http.axiosGetBy(this.$api.ep_kwsdata, {nopage: 1, id: ids.join(',')}, (response) => {
                if (response.code === 200) {
                    this.selectedKeywordList = _.map(this.selectedKeywordList, (item) => {
                        let val = _.find(response.data.list, { keyword_id: item.keyword_id});
                        let offer_array = val.offer_rank;
                        let rank_array = [];
                        _.each(time_set, (time_item) => {
                            if (time_item) {
                                let price_rank = offer_array[time_item.index];
                                price_rank = price_rank.reverse();
                                let offer = this.$common.toFormat2dot(Number(time_item.discount) /100 * Number(item.offer));
                                let position = this.$common.sortByIndex(price_rank, offer);
                                rank_array.push(position);
                            }
                        });
                        let total = 0;
                        _.each(rank_array, (it) => {
                            total += Number(it);
                        });
                        item.bid_position = Math.floor(total / rank_array.length);
                        item.pc_price = Number(item.offer);
                        //关键词竞争店铺一天的平均的出价,人群定向排名需要
                        let store_offer = _.map(val.store_rank, (_val) => {
                            return Number(_val);
                        })
                        store_offer = _.sortBy(store_offer, (tmp_val) => {
                            return -tmp_val;
                        });
                        item.price_rank = store_offer;
                        return item;
                    });
                } else {
                    this.$message({
                        type: 'success',
                        message: response.msg,
                        duration: 1000
                    });
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })



        },
        delSelectKeywords(val) {
            this.delKeyQueue = val;
        },
        toFinish() {
            if (this.selectedKeywordList.length === 0) {
                this.$message.warning('请添加关键词');
                return false;
            }
            let oneForm = this.$lockr.get("form");
            if (Number(this.form.price) === 0) {
                this.$message.warning('请设置pc端出价系数');
                return false;
            }
            let formData = {
                project_name: oneForm.project_name,
                daily_budget: oneForm.daily_budget,
                time_set: [],
                keywords: [],
                unit_name: this.form.name,
                gi_id: this.goodsId.id,
                area_set: [],
                peoples: [],
                put_tactics: oneForm.delivery_speed,
            };
            if (this.$route.query.id) {
                formData.id = Number(this.$route.query.id);
            }
            //获取时间段的设置
            _.each(oneForm.time_set, (item) => {
                if (item !== null) {
                    let time_data = {
                        time: item.index + 1,
                        discount: item.discount
                    }
                    formData.time_set.push(time_data);
                }
            });
            formData.time_set = JSON.stringify(formData.time_set);
            //获取关键词的设置
            _.each(this.selectedKeywordList, (item) => {
                let keyword_item = {
                    keyword_id: item.keyword_id,
                    keyword: item.keyword,
                    offer: Number(item.pc_price),
                    com_index: item.com_index,
                }
                formData.keywords.push(keyword_item);
            });
            formData.keywords = JSON.stringify(formData.keywords);
            //获取人群的设置
            _.each(this.tableData3, (item, index) => {
                if (item.switchValue) {
                    let people_set = {
                        type: index + 1,
                        pc_price: this.form.price,
                        premium: item.premiumFactor,
                        show_num: item.exposureQuantity,
                        click_num: item.exposureCrowd,
                        tran_num: Math.floor(item.exposureQuantity * item.tranRate / 100),
                        show_rank: item.show_rank,
                        click_rate: item.clickRate,
                        tran_rate: item.tranRate,
                    }
                    formData.peoples.push(people_set);
                }
            });
            formData.peoples = JSON.stringify(formData.peoples);
            //获取地区设置
            /*_.each(this.areaSet, (item) => {
                let id = item[0];
                let child_id = item[1];
                let info = _.find(territort, {id});
                let area_info = _.find(info.children, {id: child_id});
                let area_obj = {
                    name: area_info.name
                };
                formData.area_set.push(area_obj);
            });*/
            formData.area_set = JSON.stringify(this.areaSet);
            this.$http.axiosPost(this.$api.SDsavePromotion, formData, (res) => {
                if (res.code === 200) {
                    this.$lockr.set(`search_${res.data.id}`, formData);
                    this.$message({
                        type: 'success',
                        message: res.msg,
                        duration: 1000,
                        onClose: () => {
                            if (this.$route.query.id) {
                                this.$router.push({
                                    path: '/student/operationpromotion/searchads/createplanstepfour',
                                    query: {
                                        id: this.$route.query.id
                                    }
                                });
                            } else {
                                this.$router.push('/student/operationpromotion/searchads/createplanstepfour');
                            }
                        }
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: res.msg+'请重新设置！'
                    });
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err);
            })
        },
        //s关键词页面
        //保存关键词第一步
        saveKeywordOne() {
            this.keywordDrawer = false;
        },
        //删除已选关键词
        delToggleKeyword() {
            if (this.delKeyQueue.length === 0) {
                this.$message.warning('请选择要删除的关键词');
            }
            let select_arr = [];
            for (let i = 0; i < this.selectedKeywordList.length; i++) {
                let item = this.selectedKeywordList[i];
                let isExt = _.find(this.delKeyQueue, { keyword_id: item.keyword_id });
                if (isExt) {
                    continue;
                } else {
                    select_arr.push(item);
                }
            }
            this.selectedKeywordList = select_arr;
            this.goodsKywsed();
        },
        //保存手动添加的关键词
        addManualKeyword() {

        }
        //e关键词页面
    },
}